import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SkillsPage = () => (
  <Layout page="Index">
    <body className="font-sans antialiased text-gray-900 leading-normal tracking-wider">
      <div className="max-w-4xl flex items-center h-screen  flex-wrap mx-auto mt-24 mb-8 lg:my-0">
        <div
          id="profile"
          className="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none md:shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
        >
          <div className="p-4 md:p-12 text-center lg:text-left">
            <div className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 ">
              <StaticImage
                src="../images/hero.png"
                alt="profile image"
                className="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 "
              />
            </div>

            <h1 className="text-3xl font-bold pt-8 lg:pt-0">/skills</h1>
            <div className="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25"></div>
            <p className="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
              <svg
                className="h-4 fill-current text-green-700 pr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9 12H1v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6h-8v2H9v-2zm0-1H0V5c0-1.1.9-2 2-2h4V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1h4a2 2 0 0 1 2 2v6h-9V9H9v2zm3-8V2H8v1h4z" />
              </svg>{" "}
              Web Developer
            </p>

            <p className="pt-8 text-sm">
              As a front-end developer, I specialize in creating dynamic and
              responsive web pages using HTML, CSS, Tailwind CSS, Material UI
              and JavaScript. I have experience with various front-end
              frameworks such as GatsbyJS, React and Next.js. My design skills
              include proficiency in Figma, allowing me to create visually
              appealing interfaces that complement my code. I'm also skilled in
              optimizing web pages for speed, accessibility, and JamStack.
            </p>

            <div className="pt-8 pb-8 space-x-2 text-xs md:text-sm">
              <Link
                to="/skills"
                className="bg-white hover:bg-green-700 hover:text-white text-green-700 border-green-700 border-2 font-bold py-2 px-4 rounded-full"
              >
                /skills
              </Link>
              <Link
                to="/portfolio"
                className="bg-white hover:bg-green-700 hover:text-white text-green-700 border-green-700 border-2 font-bold py-2 px-4 rounded-full"
              >
                /projects
              </Link>
              <button className="bg-white hover:bg-green-700 hover:text-white text-green-700 border-green-700 border-2 font-bold py-2 px-4 rounded-full">
                /experiences
              </button>
            </div>
            <div className="pb-8 space-x-4">
              <button className="bg-green-700 hover:bg-green-900 hover:text-white text-white font-bold py-2 px-4 rounded-full">
                Get In Touch
              </button>
            </div>

            <div className="mt-6 pb-8 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-between">
              <a className="link" href="#" data-tippy-content="@twitter_handle">
                <svg
                  className="h-6 fill-current text-gray-600 hover:text-main"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Twitter</title>
                  <path d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z" />
                </svg>
              </a>
              <a className="link" href="#" data-tippy-content="@github_handle">
                <svg
                  className="h-6 fill-current text-gray-600 hover:text-green-700"
                  role="img"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>GitHub</title>
                  <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
                </svg>
              </a>{" "}
            </div>

            {/* <!-- Use https://simpleicons.org/ to find the svg for your preferred product -->  */}
          </div>
        </div>

        {/* <!--Img Col--> */}
        <div className="w-full lg:w-2/5">
          {/* <!-- Big profile image for side bar (desktop) --> */}
          <StaticImage
            src="../images/projects.png"
            className="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
          />
          {/* <!-- Image from: http://unsplash.com/photos/MP0IUfwrn0A --> */}
        </div>

        {/* <!-- Pin to top right corner --> */}
      </div>
    </body>
  </Layout>
)

export default SkillsPage
